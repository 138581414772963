import Pusher from "pusher-js";
import Echo from "laravel-echo";
import type { AsyncData } from "#app";
import Sawmill from "~/services/logger";

export default function usePusher() {
  const logger = new Sawmill();
  const config = useRuntimeConfig();
  //@ts-ignore
  window.Pusher = Pusher;

  let echo = new Echo({
    //@ts-ignore
    authorizer: (channel, options) => {
      return {
        authorize: async (
          socketId: any,
          callback: (arg0: boolean, arg1: any) => void,
        ) => {
          let { data, error } = (await useApiFetch("/api/broadcasting/auth", {
            method: "POST",
            body: {
              socket_id: socketId,
              channel_name: channel.name,
            },
          })) as AsyncData<any, any>;

          if (error.value) {
            logger.error("Echo auth error", error.value);
            callback(true, error.value);
            return;
          }
          callback(false, data.value);
        },
      };
    },

    // auth: {
    //   headers: {
    //     "X-XSRF-TOKEN": useCookie("XSRF-TOKEN", {
    //       domain: `.${currentUrl.hostname}`,
    //     }).value as string,
    //     Accept: "application/json",
    //   },
    // },
    //
    // authEndpoint: `${config.public.apiBase}/api/broadcasting/auth`,
    broadcaster: "reverb",
    key: config.public.reverbAppKey,
    wsHost: config.public.reverbHost,
    wsPort: config.public.reverbPort,
    wssPort: config.public.reverbPort,
    forceTLS: (config.public.reverbUrlScheme ?? "https") === "https",
    enabledTransports: ["ws", "wss"],
  });
  return {
    echo,
  };
}
