type LoggerMethod =
  | "debug"
  | "log"
  | "warn"
  | "error"
  | "groupCollapsed"
  | "groupEnd";

export default class Sawmill {
  private inGroup: boolean = false;
  private methodToColorMap: Record<LoggerMethod, string | null> = {
    debug: `#7f8c8d`,
    log: `#2ecc71`,
    warn: `#f39c12`,
    error: `#c0392b`,
    groupCollapsed: `#3498db`,
    groupEnd: null,
  };

  private print(method: LoggerMethod, args: any[]): void {
    if (
      method === "groupCollapsed" &&
      /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    ) {
      console[method](...args);
      return;
    }

    const styles = [
      `background: ${this.methodToColorMap[method]}`,
      `border-radius: 0.5em`,
      `color: white`,
      `font-weight: bold`,
      `padding: 2px 0.5em`,
    ];
    const logPrefix = this.inGroup ? [] : ["%csawmill", styles.join(";")];
    console[method](...logPrefix, ...args);

    if (method === "groupCollapsed") {
      this.inGroup = true;
    }
    if (method === "groupEnd") {
      this.inGroup = false;
    }
  }

  debug(...args: any[]): void {
    this.print("debug", args);
  }

  log(...args: any[]): void {
    this.print("log", args);
  }

  warn(...args: any[]): void {
    this.print("warn", args);
  }

  error(...args: any[]): void {
    this.print("error", args);
  }

  groupCollapsed(...args: any[]): void {
    this.print("groupCollapsed", args);
  }

  groupEnd(...args: any[]): void {
    this.print("groupEnd", args);
  }
}
